
























































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    ORegistrationForm: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-registration-form.vue"
      ),
    ORegistrationMessage: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-registration-message.vue"
      ),
  },

  setup(_, { root }) {
    const model = reactive({
      partnerGroupId: "",
      partner: "",
      scale: 1,
      state: true,
    });

    const state = reactive({
      empty: false,
      loading: false,
      loaded: false,
      success: false,
      error: false as boolean | number,
      dialog: false,
      partners: [],
      total: 0,
      groups: [],
    });

    //  Start fetch partners

    const fetchPartners = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("partner")
        .then(({ data: { partners, total } }) => {
          state.empty = false;
          state.partners = partners.map((partner: any) => ({ ...partner }));
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.partners = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchPartners);

    //  End fetch partners

    //  Start fetch partner groups

    const fetchGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/partner/group`)
        .then(({ data: { partnerGroups } }) => {
          state.empty = false;
          state.groups = partnerGroups.map((partnerGroup: any) => ({
            ...partnerGroup,
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.groups = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchGroups);

    //  End fetch partner groups

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Partner jest już przypisany do tej grupy";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = (action?: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        partnerGroupId: model.partnerGroupId,
        scale: model.scale,
        state: model.state,
      };

      state.loading = true;

      axiosInstance
        .post(`/partner/${model.partner}/assign`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;
          model.partnerGroupId = "";
          model.partner = "";
          model.scale = 1;
          model.state = true;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc(
              "event.panel.website.config.partners.single.add.add.success"
            ),
          });
          if (action === "close") {
            root.$router.push(
              (root.$route.query as { next: string }).next || {
                name: "panel.event.view.website.partner.view",
              }
            );
          }
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    const scale = [
      { value: 0.1, name: "10%" },
      { value: 0.2, name: "20%" },
      { value: 0.3, name: "30%" },
      { value: 0.4, name: "40%" },
      { value: 0.5, name: "50%" },
      { value: 0.6, name: "60%" },
      { value: 0.7, name: "70%" },
      { value: 0.8, name: "80%" },
      { value: 0.9, name: "90%" },
      { value: 1, name: "100%" },
    ];

    return { model, state, onSubmit, scale };
  },
});
